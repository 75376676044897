import { BASEURL } from "../../API/apiData";

const getProjectAssumptionCategories = async () => {
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}/assumption_categories`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectAssumptionCategories = async (data) => {
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}assumption_categories`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const updateProjectAssumptionCategories = async (data) => {
  const token = localStorage.getItem("token");
  const { id, assData } = data;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(assData),
  };

  const response = await fetch(
    `${BASEURL}assumption_categories/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectAssumptionSubCategories = async (data) => {
  const token = localStorage.getItem("token");
  const { assID, subCatData } = data;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(subCatData),
  };

  const response = await fetch(
    `${BASEURL}assumption_categories/${assID}/assumption_subcategories`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const updateProjectAssumptionSubCategories = async (data) => {
  const token = localStorage.getItem("token");
  const { assumption_category_id, id, assData } = data;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(assData),
  };

  const response = await fetch(
    `${BASEURL}assumption_categories/${assumption_category_id}/assumption_subcategories/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectAssumptionCategoriesLink = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}assumption_category_project_assumptions`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const getAllUsers = async () => {
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(`${BASEURL}users`, requestOptions);
  const results = await response.json();
  return results;
};

export {
  getProjectAssumptionCategories,
  createProjectAssumptionCategories,
  createProjectAssumptionSubCategories,
  createProjectAssumptionCategoriesLink,
  updateProjectAssumptionCategories,
  updateProjectAssumptionSubCategories,
  getAllUsers,
};
