// ==== THIS FILES HANDLES QUERIES FOR ALL PROJECTS CONTAINER ====
import { useQuery, useMutation, useQueryClient } from "react-query";

// ==== SERVICES PATHS ====
import {
  createNewProject,
  fetchAllProjectCategories,
  fetchAllProjects,
  fetchMostRecentEnagedProject,
} from "../../services/projects/ProjectService";
import getAllSdgs from "../../services/Sdgs/allSdgs";
import { follow, unFollow } from "../../services/favorites/favoriteService";

// ---- USED TO FETCH ALL PROJECTS ----
const UseFetchProjects = (queryData) => {
  const { currentPage, perPage } = queryData;
  const fetchProjects = async () => {
    const result = await fetchAllProjects(queryData);
    return result;
  };
  return useQuery({
    queryKey: ["allProjects", { page: currentPage, perPage }],
    queryFn: fetchProjects,
    keepPreviousData: true,
  });
};

// ---- USED TO FETCH ALL PROJECT CATEGORIES ----
const UseFetchAllProjectCategories = () => {
  const fetchCategories = async () => {
    const { result } = await fetchAllProjectCategories();
    return result;
  };
  return useQuery({
    queryKey: ["allProjectsCategories"],
    queryFn: fetchCategories,
  });
};

// ---- USED TO FETCH THE MOST ENGAGED PROJECT AND THE LAST 5 RECENT CREATED PROJECTS ----
const UseFetchMostRecentEngaged = () => {
  const fetchRecent = async () => {
    const { result } = await fetchMostRecentEnagedProject();
    return result;
  };

  return useQuery({
    queryKey: ["mostRecentEngagedProjects"],
    queryFn: fetchRecent,
  });
};

// ---- USED TO FETCH SDGS -----
const UseFetchSDGs = () => {
  const fetchSDGs = async () => {
    const { result } = await getAllSdgs();
    return result;
  };

  return useQuery({
    queryKey: ["sdgs"],
    queryFn: fetchSDGs,
    staleTime: 1000 * 60 * 30,
  });
};

// ---- MUTATIONS ----
const UseCreateNewProject = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (projectData) => createNewProject(projectData),
    onSuccess: () => {
      //  --- Invalidate the cache to get actual data --
      queryClient.invalidateQueries({
        queryKey: ["allProjects"],
      });

      queryClient.invalidateQueries({
        queryKey: ["mostRecentEngagedProjects"],
      });

      fn();
    },
    onError: (error, variables, context) => {
      // An error happened!
      console.log(error);
    },
  });
};

const UseCreateFollow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (projectID) => follow(projectID),
    onSuccess: (serverData, appData) => {
      //  --- Invalidate the cache to get actual data ---
      queryClient.invalidateQueries({
        queryKey: ["allProjects"],
      });
    },
  });
};

const UseDeleteFollow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (projectID) => unFollow(projectID),
    onSuccess: () => {
      //  --- Invalidate the cache to get actual data ---
      queryClient.invalidateQueries({
        queryKey: ["allProjects"],
      });
    },
  });
};

export {
  UseFetchProjects,
  UseFetchAllProjectCategories,
  UseFetchMostRecentEngaged,
  UseFetchSDGs,
  UseCreateFollow,
  UseDeleteFollow,
  UseCreateNewProject,
};
