import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/admin.css";

const AdminContainer = () => {
  return (
    <>
      <Navbar />
      <div className="admin-header">
        <span>
          <i className="fa fa-lock" aria-hidden="true"></i>&nbsp;TUC Admin Panel
        </span>
      </div>
      <div className="admin-feature-badge-container">
        <div className="admin-feature-badge classic-green">
          <Link to={"/project_manager"}>
            <i class="fa fa-folder" aria-hidden="true"></i>&nbsp; Project
            Manager
          </Link>
        </div>
        <div className="admin-feature-badge classic-orange">
          <Link to={"/badge_manager"}>
            <i className="fas fa-award"></i>&nbsp; Badge Manager
          </Link>
        </div>
        <div className="admin-feature-badge classic-red">
          <span>
            <Link to={"/engagement_manager"}>
              <i className="fa fa-area-chart" aria-hidden="true"></i>{" "}
              &nbsp;Engagment Manager
            </Link>
          </span>
        </div>
        <div className="admin-feature-badge classic-purple">
          <span>
            <Link to={"/invite"}>
              <i class="fa fa-universal-access" aria-hidden="true"></i>
              &nbsp; Invitation system
            </Link>
          </span>
        </div>
        <div className="admin-feature-badge classic-green">
          <span>
            <Link to={"/admin_messages"}>
              <i class="fa fa-envelope-square" aria-hidden="true"></i>
              &nbsp; Message Board
            </Link>
          </span>
        </div>
        <div className="admin-feature-badge classic-red">
          <span>
            <Link to={"/admin_users"}>
              <i class="fa fa-envelope-square" aria-hidden="true"></i>
              &nbsp; Emails Management
            </Link>
          </span>
        </div>
        <div className="admin-feature-badge classic-green">
          <span>
            <Link to={"/users"}>
              <i class="fa fa-users" aria-hidden="true"></i>
              &nbsp; Users
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default AdminContainer;
