import React from "react";
import Navbar from "../components/navbar/Navbar";
// ----
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
// ---
import { UseGetAllUsers } from "../hooks/Admin";

const AdminUsers = () => {
  const { data: userData, isLoading, isError } = UseGetAllUsers();

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="secondary" />
        <h6
          style={{
            margin: "0 0 0 10px",
          }}
        >
          loading....
        </h6>
      </div>
    );

  if (isError)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching the users...
        </Alert>
      </div>
    );

  return (
    <>
      <Navbar />
      <div
        className="container-fluid"
        style={{
          marginTop: "20px",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  First Name
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  Last Name
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  No of Engagements
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.map((row) => (
                <TableRow key={row.index}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "15px",
                    }}
                  >
                    {row.first_name}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "15px",
                    }}
                  >
                    {row.last_name}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      fontSize: "15px",
                    }}
                  >
                    {row.email}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: "purple",
                      fontWeight: "800",
                      fontSize: "15px",
                    }}
                  >
                    {row.engagement?.eng_count}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default AdminUsers;
