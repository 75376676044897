import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { UseFetchUser } from "../../hooks/profiles";
import Alert from "@mui/material/Alert";
import { connect } from "react-redux";
import { checkLoggedInStatus } from "../../redux";

const UserToggleNav = ({
  handleLogout,
  showUserToggleNav,
  status,
  isLoggedIn,
}) => {
  const history = useHistory();

  useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);

  const navigateBadges = () => {
    history.push({
      pathname: `/profile`,
      state: { tabID: 4 },
    });
  };

  const handleUserToggle = () => {
    showUserToggleNav();
    navigateBadges();
  };

  const displayUserToggleNav = () => {
    return (
      <>
        <Link to={"/profile"}>
          <i className="fas fa-user-circle"></i>&nbsp;&nbsp;My Profile
        </Link>
        {status?.data?.user?.user_type_id === 1 ? (
          <>
            <button
              type="button"
              className="btn-wrapper"
              onClick={showUserToggleNav}
            >
              <Link to={"/admin"}>
                <i className="fa fa-lock"></i>&nbsp;&nbsp;Admin
              </Link>
            </button>
          </>
        ) : null}
        <button
          type="button"
          className="btn-wrapper"
          onClick={handleUserToggle}
        >
          <div className="bagdes-div">
            <i className="fas fa-award"></i>&nbsp;&nbsp;Badges
          </div>
        </button>
        <button
          type="button"
          className="btn-wrapper"
          onClick={showUserToggleNav}
        >
          <Link to={"/under_construction"}>
            <i className="fas fa-envelope"></i>&nbsp;&nbsp;Messages
          </Link>
        </button>
        <button className="nav-btn-logout mt-3" onClick={handleLogout}>
          <i className="fas fa-power-off"></i>&nbsp;&nbsp;Logout
        </button>
      </>
    );
  };

  return (
    <div className="user-toggle-nav shadow-lg">
      <button
        type="button"
        className="btn-wrapper"
        onClick={showUserToggleNav}
      ></button>
      {displayUserToggleNav()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  status: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserToggleNav);
