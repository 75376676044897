/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styles from "./css/ProjectContainer.module.css";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchBar from "./ReusableComponents/SearchBar";
import ProjectCard from "./ReusableComponents/ProjectCard";
import { Button } from "@mui/material";
import _ from "lodash";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Groups3Icon from "@mui/icons-material/Groups3";
import CardContent from "@mui/material/CardContent";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ReadMoreReusable from "../reusablecomponents/ReadMoreReusable";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import AppLoadingSkeleton from "../reusablecomponents/AppLoadingSkeleton";
import Pagination from "@mui/material/Pagination";

// ==== CATEGORIES COMPONENT =====
const Categories = ({
  openCategoryModal,
  categories,
  sortedProjectCategory,
  mostRecentEngaged,
  mostLoader,
  setSortedProjectCategory,
  mostRecentEngagedProjectsError,
  navigateTo,
  projectCategoriesIsLoading,
  projectCategoriesError,
}) => {
  const displayCategories = () => {
    if (projectCategoriesIsLoading)
      return <CircularProgress color="secondary" />;

    // --- HANDLE IF THERE IS AN ERROR ---
    if (projectCategoriesError !== null)
      return (
        <Alert variant="outlined" severity="error">
          Something went wrong while fetching the project categories ...
        </Alert>
      );

    if (categories.length < 1)
      return (
        <Alert variant="outlined" severity="info">
          There are no categories at this time
        </Alert>
      );

    return categories.map((category) => {
      return (
        <div className={styles.category_item_container} key={category.id}>
          <li
            className={`list-group-item ${
              sortedProjectCategory === category.id &&
              styles.category_item_active
            }`}
            onClick={() => setSortedProjectCategory(category.id)}
          >
            <img
              src="https://i.ibb.co/2KYncTV/Business.jpg"
              className={`${styles.img_thumbnail}`}
              alt="thumb-Business"
            />{" "}
            <span className={styles.category_name}>{category.name}</span>{" "}
            <span className={styles.category_count}>
              ({category.project_count})
            </span>
          </li>
        </div>
      );
    });
  };

  const displayMostRecentProjects = () => {
    if (mostRecentEngagedProjectsError !== null)
      return (
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            marginTop: "20px",
          }}
        >
          Something went wrong while fetching data....
        </Alert>
      );

    const { recent_projects } = mostRecentEngaged;
    if (_.isEmpty(recent_projects))
      return (
        <Alert
          variant="outlined"
          severity="success"
          sx={{
            marginTop: "20px",
          }}
        >
          You dont have any recent projects
        </Alert>
      );

    return recent_projects.map((value) => {
      return (
        <div className="container-fluid" key={value.id}>
          <div className={`${styles.pinned_container} `}>
            <CardContent
              sx={{
                minHeight: "80px",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: "#000",
                  fontSize: "17px",
                }}
              >
                <ReadMoreReusable>{value.name}</ReadMoreReusable>
              </Typography>
            </CardContent>
            <CardActions
              disableSpacing
              sx={{
                backgroundColor: "#f7f7f7",
              }}
            >
              <div
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                <span
                  style={{
                    color: "purple",
                    fontWeight: 800,
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {" "}
                  {/* ({value.engagements.eng_count}) */}
                </span>
                Engagements{" "}
              </div>
              <Button
                onClick={() => navigateTo(value.id)}
                startIcon={<Groups3Icon />}
                variant="outlined"
                color="secondary"
                sx={{
                  marginLeft: "20px",
                }}
                size="small"
              >
                Engage
              </Button>
            </CardActions>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.categories_container}>
      <Card
        sx={{
          minWidth: 275,
          minHeight: "50vh",
          height: "50%",
          maxHeight: "50vh",
          overflowY: "scroll",
        }}
      >
        <CardHeader
          className={styles.card_header}
          action={
            <IconButton
              aria-label="create new category"
              onClick={() => openCategoryModal()}
            >
              <AddCircleIcon
                fontSize="large"
                sx={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          }
          sx={{
            backgroundColor: "#f7f7f7",
            color: "#000",
            fontWeight: "bold",
            fontSize: 15,
            textTransform: "uppercase",
          }}
          titleTypographyProps={{ variant: "h6" }}
          title="Project Categories"
        />

        <div className={styles.card_body}>
          <ul className={`list-group list-group-flush`}>
            <li
              className={`list-group-item ${
                sortedProjectCategory === null && styles.category_item_active
              }`}
              onClick={() => setSortedProjectCategory(null)}
            >
              <img
                src="https://i.ibb.co/2KYncTV/Business.jpg"
                className={`${styles.img_thumbnail}`}
                alt="thumb-Business"
              />{" "}
              <span className={styles.category_name}>ALL Projects</span>{" "}
            </li>
            {displayCategories()}
          </ul>
        </div>
      </Card>
      {/* ===== Latest Projects ===== */}
      <Card
        sx={{
          minWidth: 275,
          minHeight: "40vh",
          height: "40%",
          maxHeight: "40vh",
          marginTop: "20px",
          overflowY: "scroll",
        }}
      >
        <CardHeader
          className={styles.card_header}
          sx={{
            backgroundColor: "#f7f7f7",
            color: "#000",
            fontWeight: "bold",
            fontSize: 15,
            textTransform: "uppercase",
          }}
          titleTypographyProps={{ variant: "h6" }}
          title="Most recent projects"
        />
        <div className={styles.card_body}>
          {mostLoader ? (
            <div className="text-center">
              <CircularProgress color="secondary" />
              fetching data...
            </div>
          ) : (
            displayMostRecentProjects()
          )}
        </div>
      </Card>
    </div>
  );
};

// ==== PROJECTS COMPONENT ====
const Project = ({
  projects,
  setProjectModal,
  searchValue,
  setSearchValue,
  handleUnfollowAction,
  handleFollowAction,
  sortedProjectCategory,
  navigateTo,
  allProjectsError,
  allProjectsIsLoading,
  allProjects,
  paginationData,
  currentPage,
  setCurrentPage,
}) => {
  useEffect(() => {
    handleSortSelection();
  }, [sortedProjectCategory]);

  const handleSortSelection = () => {
    if (sortedProjectCategory === null) return projects;
    const result = allProjects.filter((project) => {
      return project.project_category_id === sortedProjectCategory && project;
    });
    return result;
  };

  const handlePagination = (e, value) => {
    setCurrentPage(value);
  };

  // === Display projects ====
  const displayProjects = () => {
    if (allProjectsIsLoading) return <AppLoadingSkeleton />;
    // --- HANDLE ERROR ----
    if (allProjectsError !== null)
      return (
        <Alert variant="outlined" severity="info">
          Something went wrong while loading the projects ....
        </Alert>
      );
    if (projects.length < 1)
      return (
        <Alert variant="outlined" severity="info">
          There are no projects at this time
        </Alert>
      );

    if (searchValue === null) {
      return handleSortSelection().map((project) => {
        return (
          <div
            className={`col-xl-6 col-sm-12 ${styles.project_item}`}
            key={project.id}
          >
            <ProjectCard
              project={project}
              handleFollowAction={handleFollowAction}
              handleUnfollowAction={handleUnfollowAction}
              navigateTo={navigateTo}
            />
          </div>
        );
      });
    }

    if (searchValue !== null) {
      return projects.map((project) => {
        return (
          <div
            className={`col-xl-6 col-sm-12 ${styles.project_item}`}
            key={project.id}
          >
            <ProjectCard
              project={project}
              handleFollowAction={handleFollowAction}
              handleUnfollowAction={handleUnfollowAction}
              navigateTo={navigateTo}
            />
          </div>
        );
      });
    }
  };
  return (
    <div className={styles.projects_container}>
      <div className={styles.search_bar_area}>
        <SearchBar
          projects={allProjects}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <Button
          className={styles.create_new_project_button}
          variant="outlined"
          color="secondary"
          startIcon={<DriveFolderUploadIcon />}
          style={{
            marginLeft: "30px",
          }}
          onClick={() => setProjectModal(true)}
        >
          New Project
        </Button>
      </div>
      <div className="container-fluid">
        <div
          className={`row ${styles.projects_container_row}`}
          style={{
            marginBottom: "20px",
          }}
        >
          {displayProjects()}
        </div>
        <div
          className="pagination_bar_container"
          style={{
            marginTop: "20px",
            marginBottom: "50px",
            textAlign: "center",
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={
              paginationData?.total_pages ? paginationData?.total_pages : 0
            }
            page={currentPage}
            onChange={handlePagination}
            variant="outlined"
            color="secondary"
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

// ==== PINNED PROJECTS COMPONENT ====
const PinnedProjects = ({
  projects,
  mostRecentEngaged,
  mostLoader,
  navigateTo,
  mostRecentEngagedProjectsError,
  allProjectsError,
  allProjectsIsLoading,
}) => {
  const pinDisplayAlgorithim = () => {
    // --- Loading ----
    if (allProjectsIsLoading) return <CircularProgress color="secondary" />;
    // --- Error ----
    if (allProjectsError !== null)
      return (
        <Alert
          variant="outlined"
          severity="success"
          sx={{
            marginTop: "20px",
          }}
        >
          Something went wrong while loading the pinned projects ...
        </Alert>
      );
    //  --- results ---
    const result = projects.filter((project) => {
      return project.is_favorite && project;
    });

    if (_.isEmpty(result))
      return (
        <Alert
          variant="outlined"
          severity="success"
          sx={{
            marginTop: "20px",
          }}
        >
          You dont have any pinned projects
        </Alert>
      );

    return result.map((value) => {
      return (
        <div className="container-fluid" key={value.id}>
          <div className={`${styles.pinned_container} `}>
            <CardContent
              sx={{
                minHeight: "80px",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: "#000",
                  fontSize: "17px",
                }}
              >
                <ReadMoreReusable>{value.name}</ReadMoreReusable>
              </Typography>
            </CardContent>
            <CardActions
              disableSpacing
              sx={{
                backgroundColor: "#f7f7f7",
              }}
            >
              <div
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                <span
                  style={{
                    color: "purple",
                    fontWeight: 800,
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {" "}
                  ({value.engagements.eng_count})
                </span>
                Engagements{" "}
              </div>
              <Button
                onClick={() => navigateTo(value.id)}
                startIcon={<Groups3Icon />}
                variant="outlined"
                color="secondary"
                sx={{
                  marginLeft: "20px",
                }}
                size="small"
              >
                Engage
              </Button>
            </CardActions>
          </div>
        </div>
      );
    });
  };

  const displayTrendingProjects = () => {
    if (mostRecentEngagedProjectsError !== null)
      return (
        <Alert
          variant="outlined"
          severity="error"
          sx={{
            marginTop: "20px",
          }}
        >
          Something went wrong while fetching data....
        </Alert>
      );

    const { trending_projects } = mostRecentEngaged;
    if (_.isEmpty(trending_projects))
      return (
        <Alert
          variant="outlined"
          severity="success"
          sx={{
            marginTop: "20px",
          }}
        >
          There are no any trending projects
        </Alert>
      );

    return trending_projects.map((value) => {
      return (
        <div className="container-fluid" key={value.id}>
          <div className={`${styles.pinned_container} `}>
            <CardContent
              sx={{
                minHeight: "50px",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: "#000",
                  fontSize: "17px",
                }}
              >
                <h6>ProjectName:</h6>
                <ReadMoreReusable>{value.name}</ReadMoreReusable>
              </Typography>
            </CardContent>
            <CardActions
              disableSpacing
              sx={{
                backgroundColor: "#f7f7f7",
              }}
            >
              <div
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                <span
                  style={{
                    color: "purple",
                    fontWeight: 800,
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {" "}
                  ({value.count})
                </span>
                Engagements{" "}
              </div>
              <Button
                onClick={() => navigateTo(value.id)}
                startIcon={<Groups3Icon />}
                variant="outlined"
                color="secondary"
                sx={{
                  marginLeft: "20px",
                }}
                size="small"
              >
                Engage
              </Button>
            </CardActions>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.pinned_projects_container}>
      {/* ===== Trending Projects ===== */}
      <Card
        sx={{
          minWidth: 275,
          minHeight: "40vh",
          height: "40%",
          maxHeight: "40vh",
          marginTop: "20px",
          overflowY: "scroll",
        }}
      >
        <CardHeader
          className={styles.card_header}
          sx={{
            backgroundColor: "#f7f7f7",
            color: "#000",
            fontWeight: "bold",
            fontSize: 15,
            textTransform: "uppercase",
          }}
          titleTypographyProps={{ variant: "h6" }}
          title="Trending Projects"
        />
        <div className={styles.card_body}>
          {" "}
          {mostLoader ? (
            <div className="text-center">
              <CircularProgress color="secondary" />
              fetching data...
            </div>
          ) : (
            displayTrendingProjects()
          )}
        </div>
      </Card>
    </div>
  );
};

const DesktopDisplay = ({ props }) => {
  const {
    openCategoryModal,
    categories,
    projects,
    setProjectModal,
    searchValue,
    setSearchValue,
    sortedProjectCategory,
    handleFollowAction,
    handleUnfollowAction,
    mostRecentEngaged,
    mostLoader,
    mostRecentEngagedProjectsError,
    setSortedProjectCategory,
    projectCategoriesError,
    projectCategoriesIsLoading,
    allProjectsError,
    allProjectsIsLoading,
    allProjects,
    paginationData,
    currentPage,
    setCurrentPage,
    navigateTo,
  } = props;
  return (
    <div className={styles.desktopDisplay}>
      <div className="container-fluid">
        <div className="row">
          <div className={`col-md-3`}>
            {" "}
            <div className={styles.sticky_sec}>
              <Categories
                openCategoryModal={openCategoryModal}
                categories={categories}
                projects={projects}
                mostRecentEngaged={mostRecentEngaged}
                mostLoader={mostLoader}
                mostRecentEngagedProjectsError={mostRecentEngagedProjectsError}
                setSortedProjectCategory={setSortedProjectCategory}
                sortedProjectCategory={sortedProjectCategory}
                navigateTo={navigateTo}
                projectCategoriesError={projectCategoriesError}
                projectCategoriesIsLoading={projectCategoriesIsLoading}
                allProjectsError={allProjectsError}
                allProjectsIsLoading={allProjectsIsLoading}
              />
            </div>
          </div>
          <div className="col-md-6 ">
            <Project
              projects={projects}
              setProjectModal={setProjectModal}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              handleFollowAction={handleFollowAction}
              handleUnfollowAction={handleUnfollowAction}
              sortedProjectCategory={sortedProjectCategory}
              navigateTo={navigateTo}
              allProjectsError={allProjectsError}
              allProjectsIsLoading={allProjectsIsLoading}
              allProjects={allProjects}
              paginationData={paginationData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <div className={`col-md-3`}>
            <div className={styles.sticky_sec}>
              <PinnedProjects
                projects={projects}
                mostRecentEngaged={mostRecentEngaged}
                mostLoader={mostLoader}
                mostRecentEngagedProjectsError={mostRecentEngagedProjectsError}
                handleUnfollowAction={handleUnfollowAction}
                navigateTo={navigateTo}
                allProjectsError={allProjectsError}
                allProjectsIsLoading={allProjectsIsLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopDisplay;
