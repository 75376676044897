import React from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Alert from "@mui/material/Alert";
import styles from "../css/main.module.css";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import PushPinIcon from "@mui/icons-material/PushPin";
import { Button, CardActions, CardContent, Typography } from "@mui/material";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";
import Groups3Icon from "@mui/icons-material/Groups3";

const ReleaseNotesCardDisplay = ({
  release_activity,
  handleUnfollowAction,
}) => {
  const history = useHistory();
  const { notes } = release_activity;

  const handleUnpin = (project) => {
    project.is_favorite = false;
    handleUnfollowAction(project.id);
  };

  const navigateTo = (project) => {
    history.push(`/projects/${project}`);
  };

  const displayTimeline = () => {
    if (_.isEmpty(notes))
      return (
        <Alert severity="info"> You have not yet pinned any project</Alert>
      );

    return notes.map((value) => {
      return (
        <div className="container-fluid" key={value.id}>
          <div className={`${styles.pinned_container} `}>
            <CardContent
              sx={{
                minHeight: "80px",
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  color: "#000",
                  fontSize: "17px",
                }}
              >
                <ReadMoreReusable>{value.name}</ReadMoreReusable>
              </Typography>
            </CardContent>
            <CardActions
              disableSpacing
              sx={{
                backgroundColor: "#f7f7f7",
              }}
            >
              <div
                style={{
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                <span
                  style={{
                    color: "purple",
                    fontWeight: 800,
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {" "}
                  ({value.engagements.eng_count})
                </span>
                Engagements{" "}
              </div>
              <Button
                onClick={() => navigateTo(value.id)}
                startIcon={<Groups3Icon />}
                variant="outlined"
                color="secondary"
                sx={{
                  marginLeft: "20px",
                }}
                size="small"
              >
                Engage
              </Button>
            </CardActions>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.info_card_release_container}>
      <div className={styles.info_card_header}>
        {" "}
        <TextSnippetIcon
          sx={{
            fontSize: "32px",
            marginRight: "5px",
            fontWeight: "600",
          }}
        />
        <h4 className={styles.info_card_header_title}>
          Pinned Project ({notes.length})
        </h4>
      </div>
      <div className={styles.info_card_body}>{displayTimeline()}</div>
    </div>
  );
};

export default ReleaseNotesCardDisplay;
