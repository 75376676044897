import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import SuggestionForm from "./suggestionForm";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewSuggestionModal = ({
  suggestion,
  action,
  setShow,
  suggestionCategories,
  desktopPlusBtnState,
  setDesktopPlusBtnState,
  suggestionCategoriesIsLoading,
  suggestionsCategoriesIsError,
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (desktopPlusBtnState.addSuggestion === true) {
      handleClickOpen();
    }
  }, [desktopPlusBtnState]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDesktopPlusBtnState({
      ...desktopPlusBtnState,
      addSuggestion: false,
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "10px",
            marginRight: "10px",
          }}
        >
          <Button
            onClick={handleClose}
            className="text-right"
            variant="contained"
            color="error"
          >
            {" "}
            <CloseIcon /> close
          </Button>
        </div>

        <DialogContent>
          <SuggestionForm
            suggestion={suggestion}
            action={action}
            setShow={setShow}
            suggestionCategories={suggestionCategories}
            suggestionCategoriesIsLoading={suggestionCategoriesIsLoading}
            suggestionsCategoriesIsError={suggestionsCategoriesIsError}
            closeForm={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NewSuggestionModal;
