/* eslint-disable */
import React, { useState } from "react";
import _ from "lodash";
import TagBadge from "../reusablecomponents/TagBadge";
import { connect } from "react-redux";
import ideaIcon from "../../images/idea.png";
import { editSuggestion } from "../../redux";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import ckeditor, { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const SuggestionEditForm = ({
  sug,
  suggestionCategories,
  editSuggestion,
  setOpen,
  suggestionCategoriesIsLoading,
  suggestionsCategoriesIsError,
}) => {
  let sTags = [];
  sug.tags.map((tag) => sTags.push(tag.name));

  const [category, setCategory] = useState(sug.category_id);
  const [idea, setIdea] = useState(sug.idea);
  const [purpose, setPurpose] = useState(sug.purpose);
  const [details, setDetails] = useState(sug.details);
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState(sTags);
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);
  const [imageThree, setImageThree] = useState(null);
  const [imageFour, setImageFour] = useState(null);

  const handleAddTag = () => {
    if (tag == "") {
      toast.warning("You cannot add an empty tag");
    } else {
      setTags((prevTags) => [...prevTags, tag]);
      setTag("");
    }
  };

  const handleSubmit = (e) => {
    const suggestionFormData = new FormData();

    suggestionFormData.append("suggestion[idea]", idea);
    suggestionFormData.append("suggestion[purpose]", purpose);
    suggestionFormData.append("suggestion[details]", details);
    suggestionFormData.append("suggestion[tags]", JSON.stringify(tags));
    suggestionFormData.append(
      "suggestion[suggestion_category_id]",
      category == "" ? 1 : category
    );

    if (imageOne !== null) {
      suggestionFormData.append("suggestion[sug_img_one]", imageOne);
    }
    if (imageTwo !== null) {
      suggestionFormData.append("suggestion[sug_img_two]", imageTwo);
    }
    if (imageTwo !== null) {
      suggestionFormData.append("suggestion[sug_img_three]", imageThree);
    }
    if (imageTwo !== null) {
      suggestionFormData.append("suggestion[sug_img_four]", imageFour);
    }
    e.preventDefault();
    editSuggestion(sug.id, suggestionFormData);
    setOpen(false);
    window.location.reload();
  };

  const setSelectDefaultValue = () => {
    const result = suggestionCategories.forEach((element) => {
      if (element.category.id === sug.category_id) return element.name;
    });
    return result;
  };

  const displaySuggestionCategories = () => {
    if (suggestionCategoriesIsLoading) return;
    if (suggestionsCategoriesIsError) return;

    return suggestionCategories.map((value) => (
      <option key={value.category.id} value={value.category.id}>
        {value.category.name}
      </option>
    ));
  };

  return (
    <>
      <div
        className="form-title"
        style={{
          textAlign: "center",
          color: "black",
        }}
      >
        <h5
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          {" "}
          Edit suggestion{" "}
        </h5>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="suggestion_category">Select Category</label>
          <select
            option={suggestionCategories}
            className="form-control"
            name="suggestion_category"
            id="suggestion_category"
            defaultValue={setSelectDefaultValue()}
            onChange={(e) => setCategory(e.target.value)}
          >
            {displaySuggestionCategories()}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="details">Suggestion</label>
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={details}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDetails(data);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="idea">Idea</label>
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={idea}
            onChange={(event, editor) => {
              const data = editor.getData();
              setIdea(data);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="purpose">Purpose</label>
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={purpose}
            onChange={(event, editor) => {
              const data = editor.getData();
              setPurpose(data);
            }}
          />
        </div>

        {/* <div className="form-group">
              <label htmlFor="tags">Tags</label>
              {_.isEmpty(tags) ? (
                <div className="p-2 text-muted">
                  <span>Add tags and they will appear here</span>
                </div>
              ) : (
                <>
                  <div className="tt-tags-display">
                    {tags.map((tag, idx) => (
                      <TagBadge key={idx} tag={`#${tag}`} />
                    ))}
                  </div>
                </>
              )}
              <div className="d-flex">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Add tag here"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
                <button
                  className="btn btn-success mv-sg"
                  type="button"
                  onClick={handleAddTag}
                >
                  +
                </button>
              </div>
            </div> */}
        {/* <div className="mt-3 mb-2 border p-2">
              <h6>Upload Images</h6>
              <input
                type="file"
                className="form-control border-0"
                name="image-one"
                onChange={(event) => setImageOne(event.target.files[0])}
              />
              <input
                type="file"
                className="form-control border-0"
                name="image-two"
                onChange={(event) => setImageTwo(event.target.files[0])}
              />
              <input
                type="file"
                className="form-control border-0"
                name="image-three"
                onChange={(event) => setImageThree(event.target.files[0])}
              />
              <input
                type="file"
                className="form-control border-0"
                name="image-four"
                onChange={(event) => setImageFour(event.target.files[0])}
              />
            </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button type="submit" variant="contained" color="secondary">
            Edit
          </Button>
        </div>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  editSuggestion: (id, data) => dispatch(editSuggestion(id, data)),
});

export default connect(null, mapDispatchToProps)(SuggestionEditForm);
