// ==== THIS FILES HANDLES QUERIES FOR ALL PROJECTS CONTAINER ====
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  createMainSuggestion,
  createSuggestionComment,
  fetchAllMainSuggestions,
  fetchMainSuggestionsCategories,
} from "../../services/suggestions/suggestions";

export const UseFetchMainSuggestions = (queryData) => {
  const { currentPage, perPage } = queryData;
  const fetchSuggestions = async () => {
    const result = await fetchAllMainSuggestions(queryData);
    return result;
  };
  return useQuery({
    queryKey: ["suggestions", { page: currentPage, perPage }],
    queryFn: fetchSuggestions,
    keepPreviousData: true,
  });
};

export const UseFetchMainSuggestionsCategories = () => {
  const fetchSuggestionsCategories = async () => {
    const { result } = await fetchMainSuggestionsCategories();
    return result;
  };
  return useQuery({
    queryKey: ["suggestions_categories"],
    queryFn: fetchSuggestionsCategories,
  });
};

// ---- MUTATIONS -----

export const UseCreateMainSuggestion = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createMainSuggestion(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["suggestions"],
      });

      fn();
    },
  });
};

export const UseCreateMainSuggestionComment = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createSuggestionComment(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["suggestions"],
      });
      fn();
    },
  });
};
