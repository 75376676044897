import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SuggestionEditForm from "./SuggestionEditForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SuggestionEditModal = ({
  open,
  setOpen,
  suggestionCategories,
  sug,
  suggestionCategoriesIsLoading,
  suggestionsCategoriesIsError,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Button className="text-right" variant="contained" color="error">
          {" "}
          <CloseIcon /> close
        </Button>
      </IconButton>
      <DialogContent dividers>
        <SuggestionEditForm
          suggestionCategories={suggestionCategories}
          sug={sug}
          setOpen={setOpen}
          suggestionCategoriesIsLoading={suggestionCategoriesIsLoading}
          suggestionsCategoriesIsError={suggestionsCategoriesIsError}
        />
      </DialogContent>
    </BootstrapDialog>
  );
};

export default SuggestionEditModal;
