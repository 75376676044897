import { BASEURL } from "../../API/apiData";
import axios from "axios";

const createNewProject = async (data) => {
  const token = localStorage.getItem("token");

  const response = await axios.post(`${BASEURL}projects`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

const fetchAllProjects = async ({ currentPage, perPage }) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects?page=${currentPage}&per_page=${perPage}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const fetchAllProjectCategories = async () => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(`${BASEURL}project_categories`, requestOptions);
  const results = await response.json();
  return results;
};

const fetchMostRecentEnagedProject = async () => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}most_recent_most_engaged_projects`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  createNewProject,
  fetchMostRecentEnagedProject,
  fetchAllProjects,
  fetchAllProjectCategories,
};
