import React from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styles from "../../singleProject/globals/TopProjectBar.module.css";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const SuggestionTopBar = () => {
  let history = useHistory();
  return (
    <AppBar
      position="static"
      color="transparent"
      className={styles.top_bar_container}
    >
      <div
        role="presentation"
        className={styles.bread_navigation}
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "10px",
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            marginTop: "-10px",
          }}
        >
          <StyledBreadcrumb
            component="a"
            onClick={() => history.push("/dashboard")}
            label="DashBoard"
            icon={<HomeIcon fontSize="small" />}
            className={styles.bread_project_name_one}
          />
          <StyledBreadcrumb
            className={styles.bread_project_name_active}
            component="a"
            icon={<CheckCircleIcon fontSize="small" color="#000" />}
            onClick={() => history.push("/suggestion")}
            label="suggestion box"
          />
        </Breadcrumbs>
      </div>
    </AppBar>
  );
};

export default SuggestionTopBar;
