import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import projectImage from "../../../assets/nocorevalues.png";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";
import Divider from "@mui/material/Divider";

const ProjectCard = ({ project, navigateTo }) => {
  return (
    <Card
      onClick={() => navigateTo(project.id)}
      sx={{
        cursor: "pointer",
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={projectImage}
        alt="Paella dish"
        sx={{
          objectFit: "fill",
          objectPosition: "center",
        }}
      />

      <CardContent
        sx={{
          minHeight: "100px",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            color: "#000",
            fontSize: "17px",
          }}
        >
          <ReadMoreReusable>{project.name}</ReadMoreReusable>
        </Typography>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default ProjectCard;
