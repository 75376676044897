/*eslint-disable */
import { useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import "./stylesheets/styles.css";
import "./stylesheets/reusables.css";
import "./stylesheets/backgroundcolor-palette.css";
import "react-toastify/dist/ReactToastify.css";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import store from "./redux/store";
import SignUp from "./containers/SignUp";
import SignIn from "./containers/SignIn";
import CategoriesContainer from "./containers/CategoriesContainer";
import TopTenContainer from "./containers/TopTenContainer";
import TTEntryContainer from "./containers/TTEntryContainer";
import ConstructionContainer from "./containers/ConstructionContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import LandingPage from "./containers/landingPage";
import SuggestionsContainer from "./containers/SuggestionsContainer";
import ProjectContainer from "./containers/ProjectContainer";
import SingleProjectContainer from "./containers/SingleProjectContainer";
import PwdReset from "./containers/PwdReset";
import PasswordResetRequest from "./containers/PasswordResetRequest";
import LegendContainer from "./containers/LegendsContainer";
import SuggestionShow from "./components/suggestionbox/SuggestionShow";
import AllUsersComponent from "./components/users/AllUsersComponent";
import FinancialContainer from "./containers/FinancialContainer";
import TaskContainer from "./containers/TaskContainer";
import Profile from "./containers/Profile";
import ProfileShowContainer from "./containers/ProfileShowContainer";
import TopTenShow from "./components/topTen/TopTenShow";
import DashboardContainer from "./containers/DashboardContainer";
import UpdateNoticeContainer from "./containers/UpdateNoticeContainer";
import UserRolesContainer from "./containers/UserRolesContainer";
import MasterNeedsCategoryContainer from "./containers/MasterNeedsCategoryContainer";
import MasterNeedsContainer from "./containers/MasterNeedsContainer";
import LibraryCategoryContainer from "./containers/LibraryCategoryContainer";
import LibraryContainer from "./containers/LibraryContainer";
import FavoriteContainer from "./containers/FavoriteContainer";
import TagsContainer from "./containers/TagsContainer";
import SingleTagContainer from "./containers/SingleTagContainer";
import { ColorContext } from "./context/themeContext";
import AdminContainer from "./containers/AdminContainer";
import BadgeManagerContainer from "./containers/BadgeManagerContainer";
import EngagementManagerContainer from "./containers/EngagementManagerContainer";
import projectSpeedDialContext from "./context/projectSpeedDialContext";
import Invitation from "./components/InvitationSystem/Invitation";
import UseInvitation from "./components/InvitationSystem/UseInvitation";
import Networks from "./containers/Networks";
import NetworkShowPage from "./components/Networks/reusableComponents/NetworkShowPage";
import ProjectManagerContainer from "./containers/ProjectManagerContainer";
import AdminMessages from "./containers/AdminMessages";
import AdminUsers from "./containers/AdminUsers";

const themes = {
  light: {
    foreground: "#000000",
    background: "#eeeeee",
  },
  dark: {
    foreground: "#ffffff",
    background: "#222222",
  },
};

const queryClient = new QueryClient();

const App = () => {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  const [buttons, setbuttons] = useState({
    newGoal: false,
    newAssumption: false,
    newNeed: false,
    newTop10: false,
    newDiscussion: false,
    newSuggestion: false,
    newSubProject: false,
    editProject: false,
  });

  const toggleProjectSpeedDialMenu = (value, current) => {
    switch (value) {
      case "goal":
        if (current === true)
          return setbuttons({
            newGoal: true,
            newAssumption: false,
            newNeed: false,
            newTop10: false,
            newDiscussion: false,
            newSuggestion: false,
            newSubProject: false,
          });
        return setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
        });
      case "assumption":
        if (current === true)
          return setbuttons({
            newGoal: false,
            newAssumption: true,
            newNeed: false,
            newTop10: false,
            newDiscussion: false,
            newSuggestion: false,
            newSubProject: false,
          });
        return setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
        });
      case "need":
        if (current === true)
          return setbuttons({
            newGoal: false,
            newAssumption: false,
            newNeed: true,
            newTop10: false,
            newDiscussion: false,
            newSuggestion: false,
            newSubProject: false,
          });
        return setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
        });
      case "top10":
        if (current === true)
          return setbuttons({
            newGoal: false,
            newAssumption: false,
            newNeed: false,
            newTop10: true,
            newDiscussion: false,
            newSuggestion: false,
            newSubProject: false,
          });
        return setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
        });
      case "discussion":
        if (current === true)
          return setbuttons({
            newGoal: false,
            newAssumption: false,
            newNeed: false,
            newTop10: false,
            newDiscussion: true,
            newSuggestion: false,
            newSubProject: false,
          });
        return setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
        });
      case "suggestion":
        if (current === true)
          return setbuttons({
            newGoal: false,
            newAssumption: false,
            newNeed: false,
            newTop10: false,
            newDiscussion: false,
            newSuggestion: true,
            newSubProject: false,
          });
        return setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
        });
      case "subproject":
        if (current === true)
          return setbuttons({
            newGoal: false,
            newAssumption: false,
            newNeed: false,
            newTop10: false,
            newDiscussion: false,
            newSuggestion: false,
            newSubProject: true,
          });
        return setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
        });
      case "editproject":
        if (current === true)
          return setbuttons({
            newGoal: false,
            newAssumption: false,
            newNeed: false,
            newTop10: false,
            newDiscussion: false,
            newSuggestion: false,
            newSubProject: false,
            editProject: true,
          });
        return setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
          editProject: false,
        });
      default:
        setbuttons({
          newGoal: false,
          newAssumption: false,
          newNeed: false,
          newTop10: false,
          newDiscussion: false,
          newSuggestion: false,
          newSubProject: false,
        });
        break;
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <projectSpeedDialContext.Provider
            value={{ buttons, toggleProjectSpeedDialMenu }}
          >
            <ColorContext.Provider value={themes}>
              <div>
                <Switch>
                  <Route exact path={"/"} component={LandingPage} />
                  <Route exact path={"/invite"} component={Invitation} />
                  <Route
                    path="/use_invitation/:token"
                    component={UseInvitation}
                  />
                  <Route exact path={"/signup"} component={SignUp} />
                  <Route exact path={"/signin"} component={SignIn} />

                  <Route
                    exact
                    path={"/passwordresetrequest"}
                    component={PasswordResetRequest}
                  />
                  <Route exact path={"/recovery"} component={PwdReset} />
                  <Route exact path={"/admin"} component={AdminContainer} />
                  <Route
                    exact
                    path={"/engagement_manager"}
                    component={EngagementManagerContainer}
                  />
                  <Route
                    exact
                    path={"/badge_manager"}
                    component={BadgeManagerContainer}
                  />

                  <Route
                    exact
                    path={"/project_manager"}
                    component={ProjectManagerContainer}
                  />

                  <Route
                    exact
                    path={"/suggestion"}
                    component={SuggestionsContainer}
                  />
                  <Route
                    exact
                    path={"/suggestion/:id"}
                    component={SuggestionShow}
                  />
                  <Route
                    exact
                    path={"/categories"}
                    component={CategoriesContainer}
                  />
                  <Route
                    exact
                    path={"/categories/:id"}
                    component={TopTenContainer}
                  />
                  <Route
                    exact
                    path={"/categories/:catId/topten/:tId/entries"}
                    component={TTEntryContainer}
                  />
                  <Route
                    exact
                    path={"/categories/:catId/topten/:tId/entries/:eId"}
                    component={TopTenShow}
                  />
                  <Route exact path={"/networks"} component={Networks} />
                  <Route
                    exact
                    path={"/networks/:id"}
                    component={NetworkShowPage}
                  />
                  <Route
                    exact
                    path={"/under_construction"}
                    component={ConstructionContainer}
                  />
                  <Route exact path={"/profile"} component={Profile} />
                  <Route
                    exact
                    path={"/profile/:id"}
                    component={ProfileShowContainer}
                  />
                  <Route exact path={"/users"} component={AllUsersComponent} />
                  <Route
                    exact
                    path={"/projects"}
                    component={ProjectContainer}
                  />
                  <Route
                    exact
                    path={"/projects/:id"}
                    component={SingleProjectContainer}
                  />
                  <Route exact path={"/legend"} component={LegendContainer} />
                  <Route
                    exact
                    path={"/financials"}
                    component={FinancialContainer}
                  />
                  <Route exact path={"/openitems"} component={TaskContainer} />
                  <Route
                    exact
                    path={"/dashboard"}
                    component={DashboardContainer}
                  />
                  <Route exact path={"/roles"} component={UserRolesContainer} />
                  <Route
                    exact
                    path={"/updates"}
                    component={UpdateNoticeContainer}
                  />
                  <Route
                    exact
                    path={"/needs"}
                    component={MasterNeedsCategoryContainer}
                  />
                  <Route
                    exact
                    path={"/library"}
                    component={LibraryCategoryContainer}
                  />
                  <Route
                    exact
                    path={"/needs/:id"}
                    component={MasterNeedsContainer}
                  />
                  <Route
                    exact
                    path={"/library/:id"}
                    component={LibraryContainer}
                  />
                  <Route
                    exact
                    path={"/favorites"}
                    component={FavoriteContainer}
                  />
                  <Route exact path={"/tags"} component={TagsContainer} />
                  <Route
                    exact
                    path={"/tags/:id"}
                    component={SingleTagContainer}
                  />
                  <Route
                    exact
                    path={"/admin_messages"}
                    component={AdminMessages}
                  />
                  <Route exact path={"/admin_users"} component={AdminUsers} />
                  <Redirect to="/" />
                </Switch>
              </div>
            </ColorContext.Provider>
          </projectSpeedDialContext.Provider>
        </Provider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};

export default App;
