import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createProjectAssumptionCategories,
  createProjectAssumptionCategoriesLink,
  createProjectAssumptionSubCategories,
  getAllUsers,
  getProjectAssumptionCategories,
  updateProjectAssumptionCategories,
  updateProjectAssumptionSubCategories,
} from "../../services/AdminService";

// --- THIS FETCHES ALL ADMIN CONTENTS ----
const UseFetchAllProjectAssumptionsCategory = () => {
  const fetchAssumptionCategories = async () => {
    const { result } = await getProjectAssumptionCategories();
    return result;
  };
  return useQuery({
    queryKey: ["assumptionCategories"],
    queryFn: fetchAssumptionCategories,
  });
};

const UseCreateProjectAssumptionCategory = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => createProjectAssumptionCategories(data),
    onSuccess: async (backendResponse, variable) => {
      const { result } = backendResponse;
      fn(result.id);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["assumptionCategories"],
      });
    },
  });
};

const UseEditProjectAssumptionCategory = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => updateProjectAssumptionCategories(data),
    onSuccess: async (backendResponse, variable) => {
      fn();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["assumptionCategories"],
      });
    },
  });
};

const UseCreateProjectAssumptionSubCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectAssumptionSubCategories(data),
    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["assumptionCategories"],
      });
    },
  });
};

const UseEditProjectAssumptionSubCategory = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => updateProjectAssumptionSubCategories(data),
    onSuccess: async (backendResponse, variable) => {
      fn();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["assumptionCategories"],
      });
    },
  });
};

const UseCreateLinkForProjectAssumptionAndCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectAssumptionCategoriesLink(data),
    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["assumptionCategories"],
      });
    },
  });
};

const UseGetAllUsers = () => {
  const fetchAllUsers = async () => {
    const { result } = await getAllUsers();
    return result;
  };
  return useQuery({
    queryKey: ["users"],
    queryFn: fetchAllUsers,
  });
};

export {
  UseFetchAllProjectAssumptionsCategory,
  UseCreateProjectAssumptionCategory,
  UseCreateProjectAssumptionSubCategory,
  UseCreateLinkForProjectAssumptionAndCategory,
  UseEditProjectAssumptionCategory,
  UseEditProjectAssumptionSubCategory,
  UseGetAllUsers,
};
