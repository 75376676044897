import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";

export const fetchAllMainSuggestions = async ({ currentPage, perPage }) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}suggestions?page=${currentPage}&per_page=${perPage}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export const fetchMainSuggestionsCategories = async () => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}suggestion_categories`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export const createMainSuggestion = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}suggestions`, requestOptions);
  const results = await response.json();
  return results;
};

const editSuggestionComment = async (
  suggestion_id,
  comment_id,
  commentData
) => {
  const data = {
    ...commentData,
  };
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}suggestions/${suggestion_id}/suggestion_responses/${comment_id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export const createSuggestionRemix = async (suggestion_id, data) => {
  try {
    const result = await axios.post(
      `${BASEURL}suggestions/${suggestion_id}/create_remix`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const createSuggestionComment = async (commentData) => {
  const token = localStorage.getItem("token");

  const { data, suggestion_id } = commentData;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}suggestions/${suggestion_id}/suggestion_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export default editSuggestionComment;
