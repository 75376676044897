import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// ---- material ui ----
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { Divider } from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// ---- assets ----
import projectImage from "../../../assets/nocorevalues.png";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";

const NetworkCard = ({ data }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToShowPage = (id) => {
    history.push(`/networks/${id}`);
  };

  const menuItems = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>More actions coming soon...</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>More actions coming soon...</MenuItem>
      </Menu>
    );
  };

  return (
    <Card
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        cursor: "pointer",
      }}
      onClick={() => navigateToShowPage(data.id)}
    >
      <CardMedia
        component="img"
        height="150"
        image={projectImage}
        alt="Project Image"
        sx={{
          objectFit: "contain",
          objectPosition: "center",
          marginTop: "20px",
        }}
      />
      {menuItems()}
      <CardContent
        sx={{
          minHeight: "100px",
          textAlign: "center",
          textTransform: "uppercase",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h3"
          color="text.secondary"
          sx={{
            color: "#000",
            fontSize: "20px",
            fontWeight: "700",
          }}
        >
          <ReadMoreReusable>{data.name}</ReadMoreReusable>
          <div
            style={{
              textTransform: "uppercase",
              fontWeight: 800,
              color: "purple",
              marginTop: "14px",
              fontSize: "14px",
            }}
          >
            <span
              style={{
                color: "purple",
                fontWeight: 800,
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {" "}
              ({data.projects_count})
            </span>
            Projects{" "}
          </div>
        </Typography>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default NetworkCard;
