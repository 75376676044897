import React, { Fragment } from "react";
import styles from "../../singleProject/globals/sidebar.module.css";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Divider } from "@mui/material";

const SideCategories = ({
  setTab,
  tab,
  suggestionCategories,
  suggestionCategoriesIsLoading,
  suggestionsCategoriesIsError,
}) => {
  if (suggestionCategoriesIsLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h5>loading categories...</h5>
      </div>
    );

  if (suggestionsCategoriesIsError) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h5>Something went wrong.</h5>
      </div>
    );
  }

  const displayCategoeries = () => {
    if (suggestionCategories.length === 0) {
      return (
        <div>
          <p>No categories available</p>
        </div>
      );
    }

    return suggestionCategories.map((category) => (
      <Fragment key={category.category.id}>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            "&:active": {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          onClick={() => setTab(category.category.id)}
          active={tab === null || tab === "all" ? true : false}
          className={
            tab === category.category.id ? `${styles.active_tab}` : null
          }
        >
          {`${category.category.name} (${category.count})`}
        </MenuItem>
      </Fragment>
    ));
  };

  return (
    <Sidebar backgroundColor="#fff" className={styles.side_bar_container}>
      <div
        className={styles.sidebar_top_bar}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className={styles.project_creator}>
          <h6
            style={{
              color: "#000",
              fontSize: "1.2rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              margin: "0",
            }}
          >
            Categories
          </h6>
        </div>
      </div>
      <Divider light={true} className={styles.side_bar_divider} />
      <Menu>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            "&:active": {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          onClick={() => setTab(null)}
          active={tab === null || tab === "all" ? true : false}
          className={
            tab === null || tab === "all" ? `${styles.active_tab}` : null
          }
        >
          All suggestions
        </MenuItem>

        {displayCategoeries()}
      </Menu>
    </Sidebar>
  );
};

export default SideCategories;
