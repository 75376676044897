/*eslint-disable */
import React, { useState } from "react";
import "../stylesheets/projects.css";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { createProject } from "../redux/projects/projectActions";
import { fetchProjectCategory } from "../redux/projectCategories/projectCategoriesAction";
import Navbar from "../components/navbar/Navbar";
import CreateNewCategoryModal from "../components/project/projectCategory/CreateNewCategoryModal";
import MainDisplay from "../components/All_Projects/MainDisplay";
import ProjectFormModal from "../components/All_Projects/ReusableComponents/ProjectFormModal";
import AppLoadingSkeleton from "../components/reusablecomponents/AppLoadingSkeleton";

// ==== HOOKS ====
import {
  UseCreateFollow,
  UseCreateNewProject,
  UseDeleteFollow,
  UseFetchAllProjectCategories,
  UseFetchMostRecentEngaged,
  UseFetchProjects,
  UseFetchSDGs,
} from "../hooks/project/allProjects";

const ProjectContainer = ({ fetchProjectCategory }) => {
  const history = useHistory();
  // ==== CATEGORIES STATE ====
  const [categories, setCategories] = useState([]);
  // ==== SORT CATEGORY =====
  const [sortedProjectCategory, setSortedProjectCategory] = useState(null);
  const [show, setShow] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  // ===== PROJECT DRAWER ====
  const [projectModal, setProjectModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 12;
  const queryData = {
    currentPage,
    perPage,
  };

  //  ==== REACT QUERY =====
  const {
    data: projectCategories,
    error: projectCategoriesError,
    isLoading: projectCategoriesIsLoading,
  } = UseFetchAllProjectCategories();

  const {
    data: projectsData,
    error: allProjectsError,
    isLoading: allProjectsIsLoading,
  } = UseFetchProjects(queryData);

  const {
    data: mostRecentEngagedProjectsData,
    error: mostRecentEngagedProjectsError,
    isLoading: mostRecentEngagedProjectsLoading,
  } = UseFetchMostRecentEngaged();

  const {
    data: sdgsData,
    error: sdgError,
    isLoading: sdgLoading,
  } = UseFetchSDGs();

  // ---- MUTATIONS (RQ) ----
  const followAction = UseCreateFollow();
  const unFollowAction = UseDeleteFollow();
  const ProjectCreationMutation = UseCreateNewProject(() =>
    setProjectModal(false)
  );

  // ---- HANDLE FOLLOW ACTION ----
  const handleFollowAction = (projectID) => {
    followAction.mutate(projectID);
  };

  // ---- HANDLE UNFOLLOW ACTION ----
  const handleUnfollowAction = async (projectID) => {
    unFollowAction.mutate(projectID);
  };

  // ---- CREATE A NEW PROJECT ----
  const createNewProject = async (projectData) => {
    ProjectCreationMutation.mutate(projectData);
  };

  // ---- open the project category modal ----
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //  ==== Navigate to project =====
  const navigateTo = (project) => {
    history.push(`/projects/${project}`);
  };

  // ==== DISPLAY THE PROJECTS ACCORDING TO IF THEY ARE SEARCHED OR SORTED BY CATEGORY ====

  const displayProjects = () => {
    const {
      result: paginated_projects,
      all_projects: allProjects,
      meta: paginationData,
    } = projectsData;

    if (searchValue === null && sortedProjectCategory === null) {
      return (
        <MainDisplay
          openCategoryModal={handleShow}
          // --- HANDLES FETCHING OF ALL PROJECTS AND CATEGORIES
          categories={projectCategories}
          projectCategoriesError={projectCategoriesError}
          projectCategoriesIsLoading={projectCategoriesIsLoading}
          // ----   HANDLE PROJECTS ----

          projects={paginated_projects}
          allProjects={allProjects}
          allProjectsError={allProjectsError}
          allProjectsIsLoading={allProjectsIsLoading}
          paginationData={paginationData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setProjectModal={setProjectModal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          sortedProjectCategory={sortedProjectCategory}
          setSortedProjectCategory={setSortedProjectCategory}
          handleFollowAction={handleFollowAction}
          handleUnfollowAction={handleUnfollowAction}
          // ---- HANDLES THE MOST ENGAGED AND THE MOST TRENDING PROJECT ---
          mostRecentEngaged={mostRecentEngagedProjectsData}
          mostLoader={mostRecentEngagedProjectsLoading}
          mostRecentEngagedProjectsError={mostRecentEngagedProjectsError}
          // ---- HANDLES NAVIGATION ----
          navigateTo={navigateTo}
        />
      );
    }
    if (searchValue !== null && sortedProjectCategory !== null) {
      setSortedProjectCategory(null);
      return (
        <MainDisplay
          openCategoryModal={handleShow}
          // --- HANDLES FETCHING OF ALL PROJECTS AND CATEGORIES
          categories={projectCategories}
          projectCategoriesError={projectCategoriesError}
          projectCategoriesIsLoading={projectCategoriesIsLoading}
          // ----   HANDLE PROJECTS ----
          projects={[searchValue]}
          allProjectsError={allProjectsError}
          allProjectsIsLoading={allProjectsIsLoading}
          paginationData={paginationData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setProjectModal={setProjectModal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          sortedProjectCategory={sortedProjectCategory}
          allProjects={allProjects}
          setSortedProjectCategory={setSortedProjectCategory}
          handleFollowAction={handleFollowAction}
          handleUnfollowAction={handleUnfollowAction}
          // ---- HANDLES THE MOST ENGAGED AND THE MOST TRENDING PROJECT ---
          mostRecentEngaged={mostRecentEngagedProjectsData}
          mostLoader={mostRecentEngagedProjectsLoading}
          mostRecentEngagedProjectsError={mostRecentEngagedProjectsError}
          // ---- HANDLES NAVIGATION ----
          navigateTo={navigateTo}
        />
      );
    }
    if (searchValue !== null && sortedProjectCategory === null) {
      return (
        <MainDisplay
          openCategoryModal={handleShow}
          // --- HANDLES FETCHING OF ALL PROJECTS AND CATEGORIES
          categories={projectCategories}
          projectCategoriesError={projectCategoriesError}
          projectCategoriesIsLoading={projectCategoriesIsLoading}
          // --- HANDLE PROJECTS
          projects={[searchValue]}
          allProjectsError={allProjectsError}
          allProjectsIsLoading={allProjectsIsLoading}
          paginationData={paginationData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allProjects={allProjects}
          setProjectModal={setProjectModal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          sortedProjectCategory={sortedProjectCategory}
          setSortedProjectCategory={setSortedProjectCategory}
          handleFollowAction={handleFollowAction}
          handleUnfollowAction={handleUnfollowAction}
          // ---- HANDLES THE MOST ENGAGED AND THE MOST TRENDING PROJECT ---
          mostRecentEngaged={mostRecentEngagedProjectsData}
          mostLoader={mostRecentEngagedProjectsLoading}
          mostRecentEngagedProjectsError={mostRecentEngagedProjectsError}
          // ---- HANDLES NAVIGATION ----
          navigateTo={navigateTo}
        />
      );
    }

    if (searchValue === null && sortedProjectCategory !== null) {
      return (
        <MainDisplay
          openCategoryModal={handleShow}
          // --- HANDLES FETCHING OF ALL PROJECTS AND CATEGORIES
          categories={projectCategories}
          projectCategoriesError={projectCategoriesError}
          projectCategoriesIsLoading={projectCategoriesIsLoading}
          // --- HANDLE PROJECTS
          projects={allProjects}
          allProjectsError={allProjectsError}
          allProjectsIsLoading={allProjectsIsLoading}
          paginationData={paginationData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allProjects={allProjects}
          setProjectModal={setProjectModal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          sortedProjectCategory={sortedProjectCategory}
          setSortedProjectCategory={setSortedProjectCategory}
          handleFollowAction={handleFollowAction}
          handleUnfollowAction={handleUnfollowAction}
          // ---- HANDLES THE MOST ENGAGED AND THE MOST TRENDING PROJECT ---
          mostRecentEngaged={mostRecentEngagedProjectsData}
          mostLoader={mostRecentEngagedProjectsLoading}
          mostRecentEngagedProjectsError={mostRecentEngagedProjectsError}
          // ---- HANDLES NAVIGATION ----
          navigateTo={navigateTo}
        />
      );
    }
  };

  return (
    <>
      <Navbar />{" "}
      <CreateNewCategoryModal
        show={show}
        setCategories={setCategories}
        handleClose={handleClose}
        handleShow={handleShow}
        fetchProjectCategory={fetchProjectCategory}
        showProgress={showProgress}
        setShowProgress={setShowProgress}
      />
      <ProjectFormModal
        open={projectModal}
        handleClose={() => setProjectModal(false)}
        categories={projectCategories}
        projectCategoriesError={projectCategoriesError}
        projectCategoriesIsLoading={projectCategoriesIsLoading}
        createNewProject={createNewProject}
        sdgs={sdgsData}
        sdgLoading={sdgLoading}
        sdgError={sdgError}
        ProjectCreationMutation={ProjectCreationMutation}
      />
      <div className="project-container">
        {allProjectsIsLoading ? <AppLoadingSkeleton /> : displayProjects()}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects,
});

const mapDispatchToProps = (dispatch) => ({
  createProject: (data) => dispatch(createProject(data)),
  fetchProjectCategory: () => dispatch(fetchProjectCategory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer);
