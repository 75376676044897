/* eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchSuggestions, createProject } from "../../redux";
import _ from "lodash";
import { toast } from "react-toastify";
import idea from "../../images/idea.png";
import comment from "../../images/comment.png";
import parse from "html-react-parser";
import ProjectModal from "../project/ProjectModal";
// === MATERIAL UI ===
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import ArticleIcon from "@mui/icons-material/Article";
import AvatarGroup from "@mui/material/AvatarGroup";
import SuggestionShareModal from "./SuggestionShareModal";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddLinkIcon from "@mui/icons-material/AddLink";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
// === REUSABLE HOOK ===
import useGetProfiles from "../../hooks/profiles/useGetProfiles";
import shareNewSuggestion from "../../services/suggestions/shareSuggestions";
import LoadingSpinner from "../LoadingSpinner";
import SuggestionRightDrawer from "./SuggestionRightDrawer";
import { displayDate, displayTime } from "../../helpers/timeModule";
// ==== service ==
import { linkSuggestionToProject } from "../../services/singleProject/singleProject";
import UpvotesDisplayModal from "./UpvotesDisplayModal";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
// ---- styles ---
import styles from "../singleProject/globals/appReusableCard.module.css";

const SuggestionBody = ({
  sug,
  categories,
  createProject,
  handleVote,
  fetchSuggestion,
  handleClickLinkOpen,
  setSelectedSuggestionID,
  setDisplayLinkedProjects,
  projects,
  setlistSpecificSuggestionProjects,
  setProjectsToLink,
  setEditSuggestion,
  setEditSugData,
  setCommentModal,
  setOnesuggestion,
}) => {
  const imageArr = [
    sug.sug_img_one.url,
    sug.sug_img_two.url,
    sug.sug_img_three.url,
    sug.sug_img_four.url,
  ];
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openProjectMoal, setOpenProjectModal] = useState(false);
  const [userLiked, setUserLiked] = useState(sug.user_likes);
  const [votes, setVotes] = useState(sug.votes);
  // === THIS IS FOR THE SELECTED PROFILES THAT NEED TO SHARE THE SUGGESTION
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [chosenSuggestion, setChosenSuggestion] = useState({});
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [drawerProfiles, setDrawerProfiles] = useState([]);
  const [votersToggleDrawer, setVotersToggleDrawer] = useState(false);
  const [upvoteProfiles, setVotersDrawerProfiles] = useState([]);

  const [uEvent, setUEvent] = useState("create");
  const [showMore, setShowMore] = useState({
    idea: false,
    purpose: false,
    details: false,
  });
  const history = useHistory();

  const { allProfilesLoading, allProfilesError, allProfilesData } =
    useGetProfiles();

  const handleClickOpen = () => {
    setOpenShareModal(true);
  };

  const handleClose = () => {
    setOpenShareModal(false);
  };

  const showProjectModal = () => {
    setOpenProjectModal(!openProjectMoal);
  };

  const handlePageNavigation = (id) => {
    history.push({
      pathname: `/suggestion/${id}`,
    });
  };

  const shareSuggestion = () => {
    if (selectedProfiles.length < 1)
      return toast.error("Please select one or more profiles");
    selectedProfiles.forEach(async (profile) => {
      try {
        await shareNewSuggestion(profile, selectedSuggestion);
        fetchSuggestion();
        toast.success("The suggestion shared successfully");
      } catch (error) {
        toast.error("Something went wrong");
        return;
      }
    });
    setSelectedProfiles([]);
    handleClose();
    setChosenSuggestion({});
  };

  const displayCategory = (id) => {
    let name = "";
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].category.id == id) name = categories[i].category.name;
    }

    return name;
  };

  // FILTER AND REMOVE ALREADY LINKED PROJECTS =====
  const filterProjectsToLink = () => {
    if (sug.project.length < 1) return setProjectsToLink([...projects]);
    let filterd = [];
    const { project } = sug;
    for (let i = 0; i < projects.length; i++) {
      if (project.some((element) => element.id === projects[i].id)) {
        continue;
      } else {
        filterd.push(projects[i]);
      }
    }
    setProjectsToLink([...filterd]);
  };

  const linkProject = async (projectID) => {
    let data = {
      suggetion_projects: {
        suggestion_id: sug.id,
        project_id: projectID,
      },
    };
    try {
      await linkSuggestionToProject(data);
      toast.success("suggestions linked successfully");
      fetchSuggestion();
    } catch (error) {
      toast.error("Something went wrong please try again");
    }
  };

  const handleCreateProject = (data) => {
    createProject(data)
      .then((res) => {
        const { result } = res.payload;
        linkProject(result.id);
      })
      .catch((e) => console.log("Internal Server Error"));
  };

  const voteEntry = () => {
    const xData = {
      likeable_type: "Suggestion",
      likeable_id: sug.id,
    };
    handleVote(xData);
    setUserLiked(!userLiked);
    if (userLiked) {
      setVotes((prev) => prev - 1);
    } else {
      setVotes((prev) => prev + 1);
    }
    fetchSuggestion();
  };

  const displayCreatorAvator = () => {
    if (sug.created_by.image === null)
      return <Avatar src="/broken-image.jpg" />;

    return (
      <Avatar
        alt={`${sug.created_by.first_name} ${sug.created_by.last_name}`}
        src={`${sug.created_by.image.url}`}
      />
    );
  };

  const displaySharedProfiles = () => {
    const result = sug.shared_Profiles.map((profile, idx) => {
      return (
        <Avatar
          key={idx}
          alt="profile avatar"
          src={`${profile.profile_avatar.url}`}
          className="sb-shared-icons-avatars"
        />
      );
    });

    return result;
  };

  const displayVotersProfiles = () => {
    const result = sug.users_liked.map((profile, idx) => {
      return (
        <Avatar
          key={idx}
          alt="profile avatar"
          src={`${profile.profile_avatar.url}`}
          className="sb-shared-icons-avatars"
        />
      );
    });

    return result;
  };

  const handleOpenLinkedProjects = (id, projects) => {
    setlistSpecificSuggestionProjects({
      selectedID: id,
      suggestionProjects: projects.length > 0 ? [...projects] : [],
    });
    setDisplayLinkedProjects(true);
  };

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    if (_.size(text) < 250) return <div className="text">{parse(text)}</div>;

    return (
      <>
        <div className="text">
          {isReadMore ? parse(text.slice(0, 250)) : parse(text)}
        </div>
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : "show less"}
        </span>
      </>
    );
  };

  return (
    <>
      <UpvotesDisplayModal
        votersToggleDrawer={votersToggleDrawer}
        upvoteProfiles={upvoteProfiles}
        setVotersToggleDrawer={setVotersToggleDrawer}
      />
      <SuggestionRightDrawer
        setToggleDrawer={setToggleDrawer}
        stateToggleDrawer={toggleDrawer}
        drawerProfiles={drawerProfiles}
      />
      <SuggestionShareModal
        openShareModal={openShareModal}
        handleClose={handleClose}
        allProfilesData={allProfilesData}
        setSelectedProfiles={setSelectedProfiles}
        selectedProfiles={selectedProfiles}
        shareSuggestion={shareSuggestion}
        chosenSuggestion={chosenSuggestion}
      />
      {openProjectMoal ? (
        <ProjectModal
          sugId={sug.id}
          ttId={""}
          categories={sug.project_categories}
          closeModal={showProjectModal}
          createProject={handleCreateProject}
        />
      ) : null}
      <div className="card tt-suggestion-card">
        <div className="card-body">
          {/* === Suggestion === */}
          <div
            className="suggest-idea"
            style={{
              minHeight: "140px",
            }}
          >
            {" "}
            {sug.details !== "" ? (
              <div className="suggestion-idea-title">
                <ArticleIcon /> <span> Suggestion</span>
              </div>
            ) : null}
            {sug.details !== null ? <ReadMore>{sug.details}</ReadMore> : null}
          </div>
          <div className="tt-suggestion-card-title row">
            {/* === IDEA === */}
            <div
              className="col-md-12 col-sm-12 col-12 suggest-idea"
              style={{
                minHeight: "140px",
              }}
            >
              {sug.idea === null ? null : (
                <div className="suggestion-idea-title">
                  <img src={idea} alt="thumbnail" /> <span> IDEA</span>
                </div>
              )}
              <div className="suggestion-purpose">
                {sug.idea !== null ? <ReadMore>{sug.idea}</ReadMore> : null}
              </div>
            </div>
          </div>
          {/* === PURPOSE === */}
          <div
            className="suggest-idea"
            style={{
              minHeight: "140px",
            }}
          >
            {" "}
            {sug.purpose !== "" ? (
              <div className="suggestion-idea-title">
                <FlagCircleIcon /> <span> PURPOSE</span>
              </div>
            ) : null}
            {sug.purpose !== null ? <ReadMore>{sug.purpose}</ReadMore> : null}{" "}
          </div>
          <Divider />
          {/* === COMMENTS SHARE LIKE AND VIEW BUTTONS === */}
          <div className={`sb-like-comment-btn ${styles.buttons_container}`}>
            {sug.users_liked.length < 1 ? null : (
              <div className="liked-by-container">
                <div className="suggestions-shared-profiles">
                  <AvatarGroup
                    max={2}
                    onClick={() => {
                      setVotersToggleDrawer(true);
                      setVotersDrawerProfiles([...sug.users_liked]);
                    }}
                  >
                    {displayVotersProfiles()}
                  </AvatarGroup>
                </div>
              </div>
            )}
            <button type="button">
              {userLiked ? (
                <IconButton onClick={voteEntry} className={styles.love_button}>
                  <Badge color="secondary" badgeContent={votes}>
                    <FavoriteIcon style={{ color: "red" }} fontSize="large" />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton onClick={voteEntry} className={styles.love_button}>
                  <Badge color="secondary" badgeContent={votes}>
                    <FavoriteBorderIcon
                      fontSize="large"
                      style={{ color: "black" }}
                    />
                  </Badge>
                </IconButton>
              )}{" "}
            </button>
            <button
              onClick={() => {
                setOnesuggestion({ ...sug });
                setCommentModal(true);
              }}
            >
              <Badge
                badgeContent={sug.comments.length}
                color="secondary"
                className={styles.badges}
              >
                <img src={comment} alt="sb-like-btn" />
              </Badge>
            </button>
            <button
              onClick={() => {
                setEditSugData({ ...sug });
                setEditSuggestion(true);
              }}
            >
              <DriveFileRenameOutlineTwoToneIcon style={{ fontSize: "30px" }} />
              <span>Edit</span>
            </button>
          </div>
        </div>
        <Divider />
        <div className="suggestion-creator-avatar">
          <Stack direction="row" spacing={3}>
            {displayCreatorAvator()}
            <h6>
              {sug.created_by.first_name} {sug.created_by.last_name}
            </h6>
          </Stack>
          <h6 className="date-of-suggestion-creation">
            suggested: {displayDate(sug.created_at).value}{" "}
            {displayTime(
              displayDate(sug.created_at).unit,
              displayDate(sug.created_at).value
            )}{" "}
            {sug.is_remix ? "♻️" : null}
          </h6>
        </div>
        <Divider />
        <div className="project-link-div">
          <div
            className="project-link-title"
            onClick={() => {
              handleOpenLinkedProjects(sug.id, sug.project);
            }}
          >
            <Badge
              badgeContent={sug.project.length >= 1 ? sug.project.length : "0"}
              color="primary"
            >
              <i className="fas fa-project-diagram text-info mt-1"></i>
              &nbsp;&nbsp;
              <span>Projects</span>&nbsp;&nbsp;
            </Badge>
          </div>
          <div>
            <>
              <Stack spacing={2} direction="row">
                {/* <Button
                  variant="contained"
                  startIcon={<AddBoxIcon />}
                  onClick={showProjectModal}
                >
                  New
                </Button> */}
                <Button
                  variant="contained"
                  startIcon={<AddLinkIcon />}
                  onClick={() => {
                    filterProjectsToLink();
                    handleClickLinkOpen(true);
                    setSelectedSuggestionID(sug.id);
                  }}
                >
                  link
                </Button>
              </Stack>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  suggestions: state.suggestions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSuggestion: () => dispatch(fetchSuggestions()),
  createProject: (data) => dispatch(createProject(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionBody);
/* eslint-enable */
