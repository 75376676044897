/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchSuggestionCategories,
  fetchProjects,
  fetchSuggestionResponse,
  createSuggestionResponse,
} from "../redux";
import { createVote } from "../services/votes/voteService";
import _ from "lodash";
import "../stylesheets/category.css";
import SuggestionBody from "../components/suggestionbox/SuggestionBody";
import "../stylesheets/suggestion.css";
import Navbar from "../components/navbar/Navbar";
import LinkProjectModal from "../components/suggestionbox/LinkProjectModal";
import SuggestionMobileMenu from "../components/suggestionbox/SuggestionMobileMenu";
import MobileSearchDialog from "../components/suggestionbox/MobileSearchDialog";
import AppFullDialog from "../components/reusablecomponents/AppFullDialog";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import NewSuggestionModal from "../components/suggestionbox/NewSuggestionModal";
import DisplayLinkedProjects from "../components/suggestionbox/DisplayLinkedProjects";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircularProgress from "@mui/material/CircularProgress";
import MobileSearch from "../components/suggestionbox/MobileSearch";
import Pagination from "@mui/material/Pagination";
// ------
import styles from "../stylesheets/cssModules/Suggestion.module.css";
import SideCategories from "../components/suggestionbox/globals/SideCategories";
import SuggestionTopBar from "../components/suggestionbox/globals/SuggestionTopBar";
import SuggestionEditModal from "../components/suggestionbox/SuggestionEditModal";
import CommentBox from "../components/singleProject/globals/CommentBox";
import editSuggestionComment from "../services/suggestions/suggestions";
import {
  createSuggestionCommentReply,
  editSuggestionCommentReply,
} from "../services/suggestions/suggestionCommentReplies";
import {
  UseCreateMainSuggestionComment,
  UseFetchMainSuggestions,
  UseFetchMainSuggestionsCategories,
} from "../hooks/SuggestionBox";
import {
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../services/projects/ProjectAssumptionService";

const SuggestionsContainer = ({
  fetchProjects,
  createSugResponse,
  fetchSugResponse,
}) => {
  const [show, setShow] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [action, setAction] = useState("create");
  const [projects, setAllProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSuggestionID, setSelectedSuggestionID] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // === MOBILE MENU STATE ===
  const [searchButton, setSearchButton] = useState(false);
  const [mobileSuggestionForm, setMobileSuggestionForm] = useState(false);
  const [viewTrending, setViewTrending] = useState(false);
  // === MOBILE SEARCH STATE IMPLEMENTATION ===
  const [mobileSearchSuggestion, setMobileSearchSuggestion] = useState(null);
  // === DESKTOP PLUS BUTTON STATE IMPLEMENTATION ===
  const [desktopPlusBtnState, setDesktopPlusBtnState] = useState({
    search: false,
    addSuggestion: false,
  });
  // ===== THIS IS THE STATE RESPONSIBLE FOR LINKED PROJECTS LOGIC ====
  const [displayLinkedProjects, setDisplayLinkedProjects] = useState(false);
  const [listSpecificSuggestionProjects, setlistSpecificSuggestionProjects] =
    useState({
      selectedID: null,
      suggestionProjects: [],
    });
  // ===== LINKING PROJECTS ====
  const [projectsToLink, setProjectsToLink] = useState([]);
  const [tab, setTab] = useState(null);
  // ==== SUGGESTION EDIT ====
  const [editSuggestion, setEditSuggestion] = useState(false);
  const [editSugData, setEditSugData] = useState({});
  const [commentModal, setCommentModal] = useState(false);
  const [oneSuggestion, setOnesuggestion] = useState({});
  const [commentDetails, setCommentDetails] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  // ---- PAGINATION ----
  const [currentPage, setCurrentPage] = useState(1);

  const fetchSuggestionComments = async () => {
    try {
      const results = await fetchSugResponse(oneSuggestion.id);
      const { result } = results.payload;
      setOnesuggestion({ ...oneSuggestion, comments: [...result] });
    } catch (error) {
      console.log(error);
    }
  };

  // --- REACT QUERY ----
  const perPage = 9;
  const queryData = {
    currentPage,
    perPage,
  };

  const {
    data: suggestionsData,
    isLoading: suggestionsIsLoading,
    isError: suggestionsIsError,
  } = UseFetchMainSuggestions(queryData);

  const {
    data: suggestionCategories,
    isLoading: suggestionCategoriesIsLoading,
    isError: suggestionsCategoriesIsError,
  } = UseFetchMainSuggestionsCategories();

  const createMainSuggestionCommentMutation = UseCreateMainSuggestionComment(
    () => {
      fetchSuggestionComments();
      setSubmitButton(false);
    }
  );

  const handlePagination = (e, value) => {
    setCurrentPage(value);
  };
  // ==== FETCH ALL PROJECTS ====
  const getAllProjects = async () => {
    try {
      const { payload } = await fetchProjects();
      setAllProjects(payload.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const sendVote = async (data) => {
    try {
      const res = await createVote(data);
      res.message == "Thanks for your vote"
        ? toast.success(res.message)
        : toast.error(res.message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVote = (data) => {
    sendVote(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // === THIS IS THE FUNCTIONS THAT HANLES THE DISPLAY OF SUGGESTIONS ===
  const handleSuggestionsDisplay = () => {
    const { result: suggestions } = suggestionsData;
    if (mobileSearchSuggestion !== null && tab === null) {
      // ==== This handles the search for mobile
      return (
        <div
          className="suggestion-mobile-search-results"
          style={{
            width: "100%",
          }}
        >
          <div className="col-md-4">
            <SuggestionBody
              desktopPlusBtnState={desktopPlusBtnState}
              setDesktopPlusBtnState={setDesktopPlusBtnState}
              setAction={setAction}
              sug={mobileSearchSuggestion}
              categories={suggestionCategories}
              handleVote={handleVote}
              handleClickOpen={handleClickOpen}
              setSelectedSuggestionID={setSelectedSuggestionID}
              setDisplayLinkedProjects={setDisplayLinkedProjects}
              displayLinkedProjects={displayLinkedProjects}
              setlistSpecificSuggestionProjects={
                setlistSpecificSuggestionProjects
              }
              listSpecificSuggestionProjects={listSpecificSuggestionProjects}
              projects={projects}
              setProjectsToLink={setProjectsToLink}
              setEditSuggestion={setEditSuggestion}
              setEditSugData={setEditSugData}
              setCommentModal={setCommentModal}
              setOnesuggestion={setOnesuggestion}
            />
          </div>
        </div>
      );
    }

    // ==== IF THERE IS NO CATEGORY CHOSEN ====
    if (tab === null)
      return suggestions.map((sug) => {
        return (
          <div className="col-md-4">
            <SuggestionBody
              desktopPlusBtnState={desktopPlusBtnState}
              setDesktopPlusBtnState={setDesktopPlusBtnState}
              key={sug.id}
              sug={sug}
              categories={suggestionCategories}
              handleVote={handleVote}
              handleClickLinkOpen={handleClickOpen}
              setSelectedSuggestionID={setSelectedSuggestionID}
              setAction={setAction}
              setDisplayLinkedProjects={setDisplayLinkedProjects}
              displayLinkedProjects={displayLinkedProjects}
              setlistSpecificSuggestionProjects={
                setlistSpecificSuggestionProjects
              }
              listSpecificSuggestionProjects={listSpecificSuggestionProjects}
              projects={projects}
              setProjectsToLink={setProjectsToLink}
              setEditSuggestion={setEditSuggestion}
              setEditSugData={setEditSugData}
              setCommentModal={setCommentModal}
              setOnesuggestion={setOnesuggestion}
            />
          </div>
        );
      });

    // ==== This handles the filter of suggestions in both mobile and desktop
    if (tab !== null) {
      // ==== IF THERE IS A CATEGORY CHOSEN ====

      const filtredByCategory = suggestions.filter((value) => {
        if (value.category_id == tab) return value;
      });

      const displayFilteredItems = filtredByCategory.map((sug) => {
        return (
          <div className="col-md-4">
            <SuggestionBody
              desktopPlusBtnState={desktopPlusBtnState}
              key={sug.id}
              sug={sug}
              categories={suggestionCategories}
              handleVote={handleVote}
              handleClickLinkOpen={handleClickOpen}
              setSelectedSuggestionID={setSelectedSuggestionID}
              setDesktopPlusBtnState={setDesktopPlusBtnState}
              setAction={setAction}
              setDisplayLinkedProjects={setDisplayLinkedProjects}
              displayLinkedProjects={displayLinkedProjects}
              setlistSpecificSuggestionProjects={
                setlistSpecificSuggestionProjects
              }
              listSpecificSuggestionProjects={listSpecificSuggestionProjects}
              projects={projects}
              setProjectsToLink={setProjectsToLink}
              setEditSuggestion={setEditSuggestion}
              setEditSugData={setEditSugData}
              setCommentModal={setCommentModal}
              setOnesuggestion={setOnesuggestion}
            />
          </div>
        );
      });
      return displayFilteredItems;
    }

    // ==== This handles the condition for search bar in desktop display ====
    if (searchInput.length > 1) {
      return items.map((sug) => {
        return (
          <div className="col-md-4">
            <SuggestionBody
              desktopPlusBtnState={desktopPlusBtnState}
              setDesktopPlusBtnState={setDesktopPlusBtnState}
              setAction={setAction}
              key={sug.id}
              sug={sug}
              categories={suggestionCategories}
              handleVote={handleVote}
              handleClickOpen={handleClickOpen}
              setSelectedSuggestionID={setSelectedSuggestionID}
              setDisplayLinkedProjects={setDisplayLinkedProjects}
              displayLinkedProjects={displayLinkedProjects}
              setlistSpecificSuggestionProjects={
                setlistSpecificSuggestionProjects
              }
              listSpecificSuggestionProjects={listSpecificSuggestionProjects}
              projects={projects}
              setProjectsToLink={setProjectsToLink}
              setEditSuggestion={setEditSuggestion}
              setEditSugData={setEditSugData}
              setCommentModal={setCommentModal}
              setOnesuggestion={setOnesuggestion}
            />
          </div>
        );
      });
    }
  };

  const showData = () => {
    if (suggestionsIsLoading)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            left: "40%",
            marginTop: "50px",
          }}
        >
          <CircularProgress color="secondary" />

          <h6
            style={{
              marginLeft: "30px",
              marginTop: "10px",
              textTransform: "uppercase",
              fontWeight: "bolder",
              color: "purple",
            }}
          >
            Loading suggestions...
          </h6>
        </div>
      );

    if (suggestionsIsError)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            left: "30%",
            marginTop: "50px",
          }}
        >
          <Alert
            variant="filled"
            severity="error"
            sx={{
              height: "50px",
            }}
          >
            Something went wrong while fetching the suggestions, kindly relaod
            and try again
          </Alert>
        </div>
      );

    const { result: suggestions, meta: paginationData } = suggestionsData;
    if (!_.isEmpty(suggestions)) {
      return (
        <>
          <SuggestionTopBar />
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "15%",
                position: "relative",
                top: "12px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "uppercase",
                }}
                color="secondary"
                startIcon={<AddCircleIcon fontSize="large" />}
                onClick={() => {
                  setDesktopPlusBtnState({
                    ...desktopPlusBtnState,
                    addSuggestion: true,
                  });
                }}
              >
                Add suggestion
              </Button>
            </div>
            <div
              style={{
                width: "80%",
              }}
            >
              <MobileSearch
                setMobileSearchSuggestion={setMobileSearchSuggestion}
                suggestions={suggestions}
                searchButton={searchButton}
                setSearchButton={setSearchButton}
              />
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">{handleSuggestionsDisplay()}</div>
            <div
              className="pagination_bar_container"
              style={{
                marginTop: "20px",
                marginBottom: "50px",
                textAlign: "center",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={
                  paginationData?.total_pages ? paginationData?.total_pages : 0
                }
                page={currentPage}
                onChange={handlePagination}
                variant="outlined"
                color="secondary"
                size="large"
              />
            </div>
          </div>
        </>
      );
    }
    if (_.isEmpty(suggestions)) {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "15%",
                position: "relative",
                top: "12px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "uppercase",
                }}
                color="secondary"
                startIcon={<AddCircleIcon fontSize="large" />}
                onClick={() => {
                  setDesktopPlusBtnState({
                    ...desktopPlusBtnState,
                    addSuggestion: true,
                  });
                }}
              >
                Add suggestion
              </Button>
            </div>
            <div
              style={{
                position: "relative",
                top: "100px",
                left: "20%",
              }}
            >
              <Alert
                variant="filled"
                severity="info"
                sx={{
                  height: "50px",
                }}
              >
                There are no Suggestions. But you can create a new one!!!
              </Alert>
            </div>
          </div>
        </>
      );
    }
  };

  const handleCreateComment = async () => {
    setSubmitButton(true);
    const commentData = {
      suggestion_id: oneSuggestion.id,
      data: {
        suggestion_response: {
          response_text: commentDetails,
          suggestion_id: oneSuggestion.id,
        },
      },
    };

    createMainSuggestionCommentMutation.mutate(commentData);
    setCommentDetails("");
  };
  const handleEditOfComment = async (editState, setEditState) => {
    const { itemId, content } = editState;
    const data = {
      suggestion_response: {
        response_text: content,
        suggestion_id: oneSuggestion.id,
        id: itemId,
      },
    };
    try {
      const { message } = await editSuggestionComment(
        oneSuggestion.id,
        itemId,
        data
      );
      if (message === "Response updated successfully") {
        fetchSuggestionComments();
        setEditState({
          form: false,
          itemId: null,
          content: "",
        });
      }
    } catch (error) {
      toast.error(`Something went wrong ${error.message}`);
    }
  };
  const handleUpvoteOfComment = async (item) => {
    const data = {
      upvote: {
        upvoteable_id: item.id,
        upvoteable_type: "SuggestionResponse",
      },
    };
    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        fetchSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
    }
  };

  const handleUnvoteOfComment = async (item) => {
    const data = {
      upvoteable_id: item.id,
      upvoteable_type: "SuggestionResponse",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        fetchSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
    }
  };
  const handleCreateOfCommentReponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    try {
      await createSuggestionCommentReply(
        oneSuggestion.id,
        itemReplyId,
        replyContent
      );
      fetchSuggestionComments();
      setReplyState({
        display: false,
        itemReplyId: itemReplyId,
        replyContent: "",
      });
    } catch (error) {
      toast.error(`Something went wrong,${error.message}`);
    }
  };
  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    commentID
  ) => {
    const { responseID, content } = editCommentResponse;
    try {
      await editSuggestionCommentReply(
        oneSuggestion.id,
        commentID,
        responseID,
        content
      );
      fetchSuggestionComments();
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    } catch (error) {
      toast.error(`Something went wrong,${error.message}`);
    }
  };
  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "SuggestionCommentReply",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        fetchSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
    }
  };
  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "SuggestionCommentReply",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        fetchSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
    }
  };
  const handleCreateOfCommentReplyOfReply = async () => {};
  const handleEditOfReplyOfReply = async () => {};
  const handleUpvoteOfCommentReplyOfReply = async () => {};
  const handleUnvoteOfCommentReplyOfReply = async () => {};

  // const { result: suggestions } = suggestionsData;
  return (
    <>
      <Navbar />
      <ToastContainer />
      <CommentBox
        open={commentModal}
        setOpen={setCommentModal}
        comments={oneSuggestion?.comments || []}
        handleCreateComment={handleCreateComment}
        detail={commentDetails}
        setCommentBoxDetail={setCommentDetails}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label="Suggestion"
        labelDetails={oneSuggestion?.details || ""}
        commentBoxIdea={oneSuggestion?.idea || ""}
        commentBoxPurpose={oneSuggestion?.purpose || ""}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={false}
        refreshGoals={() => console.log("Refresh")}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentReponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        handleCreateOfCommentReplyOfReply={handleCreateOfCommentReplyOfReply}
        handleEditOfReplyOfReply={handleEditOfReplyOfReply}
        handleUpvoteOfCommentReplyOfReply={handleUpvoteOfCommentReplyOfReply}
        handleUnvoteOfCommentReplyOfReply={handleUnvoteOfCommentReplyOfReply}
      />
      <SuggestionEditModal
        open={editSuggestion}
        setOpen={setEditSuggestion}
        suggestionCategories={suggestionCategories}
        sug={editSugData}
        suggestionCategoriesIsLoading={suggestionCategoriesIsLoading}
        suggestionsCategoriesIsError={suggestionsCategoriesIsError}
      />
      <DisplayLinkedProjects
        displayLinkedProjects={displayLinkedProjects}
        setDisplayLinkedProjects={setDisplayLinkedProjects}
        listSpecificSuggestionProjects={listSpecificSuggestionProjects}
        setlistSpecificSuggestionProjects={setlistSpecificSuggestionProjects}
      />
      <LinkProjectModal
        open={open}
        handleClose={handleClose}
        projects={projectsToLink}
        selectedSuggestionID={selectedSuggestionID}
        fetchSuggestion={() => console.log("refresh")}
      />
      <MobileSearchDialog
        searchButton={searchButton}
        setSearchButton={setSearchButton}
        setMobileSearchSuggestion={setMobileSearchSuggestion}
        suggestions={suggestionsData?.result}
      />
      <AppFullDialog
        setOpen={setMobileSuggestionForm}
        open={mobileSuggestionForm}
        details={"Add Suggestion"}
        action={action}
        setShow={setShow}
        suggestionCategories={suggestionCategories}
      />

      <NewSuggestionModal
        action={action}
        setShow={setShow}
        suggestionCategories={suggestionCategories}
        suggestionCategoriesIsLoading={suggestionCategoriesIsLoading}
        suggestionsCategoriesIsError={suggestionsCategoriesIsError}
        desktopPlusBtnState={desktopPlusBtnState}
        setDesktopPlusBtnState={setDesktopPlusBtnState}
      />

      <div className={styles.suggestion_container}>
        <div className={styles.suggestion_categories}>
          {" "}
          <SideCategories
            suggestionCategories={suggestionCategories}
            suggestionCategoriesIsLoading={suggestionCategoriesIsLoading}
            suggestionsCategoriesIsError={suggestionsCategoriesIsError}
            tab={tab}
            setTab={setTab}
          />{" "}
        </div>
        <div className={styles.suggestions_data}>{showData()}</div>
      </div>
      {/* ==== SUGGESTION PLUS BUTTON DISPLAY ==== */}

      <SuggestionMobileMenu
        setSearchButton={setSearchButton}
        setMobileSuggestionForm={setMobileSuggestionForm}
        setViewTrending={setViewTrending}
        setSelectedCategory={setSelectedCategory}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  suggestions: [],
});

const mapDispatchToProps = (dispatch) => ({
  fetchSuggestionCategories: () => dispatch(fetchSuggestionCategories()),
  fetchProjects: () => dispatch(fetchProjects()),
  fetchSugResponse: (sId) => dispatch(fetchSuggestionResponse(sId)),
  createSugResponse: (sId, data) =>
    dispatch(createSuggestionResponse(sId, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestionsContainer);
